import DynamicSectionElement from '@/scripts/dynamic-section-element.js'
import { disablePageScroll, enablePageScroll, clearQueueScrollLocks } from 'scroll-lock'

const SEARCH_OPEN_CLASS = 'open-search'

class MenuSlideout extends DynamicSectionElement {
  constructor() {
    super();

    this.setHeightSlideoutInner()
  }

  setHeightSlideoutInner() {
    const itemEls = this.querySelectorAll('.js-menu-slideout-item-inner')
    const menuEl = this.querySelector('.js-menu-slideout-content')
    const height = menuEl ? menuEl.offsetHeight : 0
    if (height && itemEls) {
      itemEls.forEach(item => {
        item.style.height = height + 'px'
      })
    }
  }

  openDrawer (event) {
    if (event && event.preventDefault) {
      if (event.metaKey) {
        return
      }
      event.preventDefault()
    }

    this.classList.add('open-slideout')
    document.body.classList.add('overflow-hidden')
    document.body.classList.add('is-open-slideout')
    const scrollableMenuSlideoutEl = document.querySelector('menu-slideout')
    const scrollablePredictiveSearchEl = document.querySelector('predictive-search')
    const announcementBarEl =  document.querySelector('announcement-bar')
    if (scrollableMenuSlideoutEl) {
      disablePageScroll(scrollableMenuSlideoutEl)
      const announcementBarHieght = announcementBarEl ? announcementBarEl.offsetHeight : 0
      document.body.style.setProperty('--vh-menu-slideout', (window.innerHeight - announcementBarHieght) / 100 + 'px')
    }
    if (scrollablePredictiveSearchEl) {
      disablePageScroll(scrollablePredictiveSearchEl)
    }
  }

  closeDrawer () {
    this.classList.remove('open-slideout')
    document.body.classList.remove('overflow-hidden', 'is-open-slideout', 'is-open-menu-level-2', 'is-open-menu-level-3' )
    clearQueueScrollLocks()
    enablePageScroll()
  }

  toggleSearchBar () {
    const searchContainer = this.querySelector('[data-search]')
    if (searchContainer.classList.contains('active')) {
      searchContainer.classList.remove('active')
      document.body.classList.remove('overflow-hidden')
      document.body.classList.remove(SEARCH_OPEN_CLASS)
    } else {
      searchContainer.classList.add('active')
      document.body.classList.add('overflow-hidden')
      document.body.classList.add(SEARCH_OPEN_CLASS)
    }
  }
}

customElements.define('menu-slideout', MenuSlideout)
